import React from 'react';
import styles from './Background.module.css';

export interface BackgroundProps {
  children: React.ReactNode;
  flexDirection?: 'row' | 'column';
}

/**
 * @param children
 * @param onReached
 * @param camPosition Where to place the camera
 * @param renderPosition From to render the page, defaults to camPosition. If only this changed (and not camPosition) the old child will be maintained.
 * @constructor
 */
export const Background = ({ children }: BackgroundProps) => {
  return (
    <div className={styles['background_root']}>
      <div className={styles['background_child-container']}>{children}</div>
    </div>
  );
};
