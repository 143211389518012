import type { BoardProps } from 'boardgame.io/react';
import { IPlayer, GameState, BoardPropTypes, CtxWithApi, Answer } from './Types';
import { Game, PhaseConfig, PlayerID } from 'boardgame.io';
import { ActivePlayers, INVALID_MOVE } from 'boardgame.io/core';
import { movesUtil } from './utils/moves.util';
import { DESIRED_AMOUNT_OF_QUESTIONS } from './promptTypes';
import { assertIsDefined } from './utils/typeUtils';
export const getSettingsForPromptIndex = (promptIndex: number) => {
  if (promptIndex < 3) {
    return {
      initialScore: 1000,
      lossPerPlayer: 200,
      showCountdown: promptIndex === 0,
      lossPerSecond: null,
      round: 1,
    };
  } else if (promptIndex < 6) {
    return {
      initialScore: 2000,
      lossPerPlayer: 400,
      showCountdown: promptIndex === 3,
      lossPerSecond: null,
      round: 2,
    };
  } else {
    return {
      initialScore: 3000,
      lossPerPlayer: 600,
      showCountdown: promptIndex === 6,
      lossPerSecond: 30,
      round: 3,
    };
  }
};
export enum Phases {
  Sync = 'Sync',
  Explainer = 'Explainer',
  PickCategory = 'PickCategory',
  Prompting = 'Prompting',
  Playing = 'Playing',
  Scoring = 'Scoring',
  ViewingHighScore = 'ViewingHighScore',
  GameEnd = 'gameEnd',
}

interface SetupData {
  drawingSocketUrl: string;
  players?: IPlayer[];
  extras?: any;
}

export type G = GameState;

export const UNPICKED_CATEGORY = '$$$UNPICKED_CAT$$$';

export const getInitialPromptState = (players: IPlayer[]) => {
  const answerIndexForPromptIndexForPlayer = players.reduce<{ [key: PlayerID]: Array<Answer> }>((acc, player) => {
    acc[player.id] = [];
    return acc;
  }, {});
  return {
    didFailPrompt: false,
    promptResults: [],
    promptIndex: 0,
    playerIdWithPromptIndexToAnswer: answerIndexForPromptIndexForPlayer,
    endedRevealingLastSentenceAt: null,
    category: UNPICKED_CATEGORY,
    selectedQuizData: null,
    powerUpsUsedForPromptIndex: {},
    quizId: null,
    highScore: null,
    playedQuizId: null,
  };
};

type BoardPropsExtended = {
  moves: { [key in keyof typeof movesUtil]: (...arg: any) => void };
  ctx: CtxWithApi;
  playerID: PlayerID;
} & BoardProps<BoardPropTypes>;
export type GameObject = BoardPropsExtended;

let isPlayAgain = false;
const setup = (ctx: CtxWithApi, setupData?: SetupData): GameState => {
  // Weird server bug that only happens first time on local
  if (setupData === undefined) {
    return {} as any;
  }
  isPlayAgain = setupData?.extras?.isPlayAgain ?? false;

  assertIsDefined(setupData.players);
  const initPlayers = setupData.players;
  const players: IPlayer[] = initPlayers.map((player, index) => {
    return {
      id: index.toString(),
      name: player?.name || `Player ${index}`,
      avatarUrl: player?.avatarUrl || `https://i.pravatar.cc/300?img=${Math.random()}`,
      active: true,
      controller_id: player?.controller_id,
      isBot: false,
    };
  });

  const category = isPlayAgain ? UNPICKED_CATEGORY : setupData?.extras?.category ?? UNPICKED_CATEGORY;
  const categoryToQuizId: Record<string, number | null> = {
    Celebrities: 1485,
    'Harry Potter': 1479,
    History: 1483,
    Marvel: 1482,
    Sports: 1489,
    'Star Wars': 1496,
    Wildlife: 1490,
    Disney: 1476,
  };

  // Game State
  return {
    didGameEnd: false,
    isTransition: true,
    phaseStartTime: Date.now(),
    players,
    shouldForceSubmit: false,
    ...getInitialPromptState(players),
    category: category,
    quizId: categoryToQuizId[category] ?? null,
    selectedAnswerForPromptIndex: {},
  };
};

const basePhase = {
  turn: {
    activePlayers: ActivePlayers.ALL,
  },
  moves: {
    setPromptResult: movesUtil.setPromptResult,
    timesUp: movesUtil.timesUp,
    forceEndPhase: movesUtil.forceEndPhase,
    transitionTimeUp: movesUtil.transitionTimeUp,
    saveQuizAfterCreation: movesUtil.saveQuizAfterCreation,
    setPlayedQuizId: movesUtil.setPlayedQuizId,
  },
  onEnd: (G: G) => {
    G.isTransition = true;
    G.shouldForceSubmit = false;
    G.phaseStartTime = Date.now();
  },
};

const AllPhases: Record<Phases, PhaseConfig<G, CtxWithApi>> = {
  [Phases.Sync]: {
    ...basePhase,
    moves: {
      ...basePhase.moves,
      endSync: movesUtil.endSync,
    },
    next: () => (isPlayAgain ? Phases.PickCategory : Phases.Explainer),
    start: true,
  },
  [Phases.ViewingHighScore]: {
    ...basePhase,
    moves: {
      ...basePhase.moves,
      chooseCategory: movesUtil.chooseCategory,
    },
    next: (G) => (G.promptResults.length === 0 ? Phases.Prompting : Phases.Playing),
  },
  [Phases.Explainer]: {
    ...basePhase,
    moves: {
      ...basePhase.moves,
      finishedTalking: (G, ctx) => {
        ctx.events.endPhase();
      },
    },
    next: (G) =>
      G.category === UNPICKED_CATEGORY
        ? Phases.PickCategory
        : G.promptResults === undefined || G.promptResults.length === 0
        ? Phases.Prompting
        : Phases.Playing,
  },
  [Phases.PickCategory]: {
    ...basePhase,
    moves: {
      ...basePhase.moves,
      chooseCategory: movesUtil.chooseCategory,
    },
    next: (G) => (G.promptResults.length === 0 ? Phases.Prompting : Phases.Playing),
  },
  [Phases.Prompting]: {
    ...basePhase,
    endIf: (G, ctx) => G.promptResults.length > G.promptIndex || G.didFailPrompt,
    moves: {
      ...basePhase.moves,
      failPrompt: movesUtil.failPrompt,
    },
    next: (G) => (G.didFailPrompt ? Phases.PickCategory : Phases.Playing),
  },
  [Phases.Playing]: {
    ...basePhase,
    next: Phases.Scoring,
    moves: {
      ...basePhase.moves,
      selectAnswer: movesUtil.selectAnswer,
      endReveal: movesUtil.endReveal,
      displayTimesUpPlaying: (G, ctx) => {
        if (ctx.playerID === '0') {
          ctx.events.endPhase();
          return;
        }
        return INVALID_MOVE;
      },
      usePowerUp: movesUtil.usePowerUp,
    },
    onEnd: (G) => {
      G.endedRevealingLastSentenceAt = null;

      basePhase.onEnd(G);
    },
  },
  [Phases.Scoring]: {
    ...basePhase,
    next: (G) => {
      if (G.promptIndex === DESIRED_AMOUNT_OF_QUESTIONS) {
        return Phases.GameEnd;
      }
      if (G.promptIndex >= G.promptResults.length) {
        return Phases.Prompting;
      }
      return Phases.Playing;
    },
    moves: {
      ...basePhase.moves,
    },
  },
  [Phases.GameEnd]: {
    ...basePhase,
    moves: {
      ...basePhase.moves,
    },
  },
};

export const GAME_ID = 'gptrivia';

export const GPTrivia: Game<G, CtxWithApi> = {
  name: GAME_ID,
  setup: setup,
  moves: movesUtil,
  phases: AllPhases,
};
