import React, { useEffect } from 'react';
import { useGameObject } from '../store/GameContext';
import styles from './Scoring.module.css';
import { UserIcon } from '@magicyard/gptrivia-shared/components/UserIcon';
import { speakText } from '../textToSpeech';
import { getTotalScore } from '@magicyard/gptrivia-game/src/utils/moves.util';

export const Scoring = () => {
  const { G } = useGameObject();
  const teamScore = getTotalScore(G);
  useEffect(() => {
    speakText(`The team score is ${teamScore}`);
  }, []);
  return (
    <div className={styles['scoring_root']}>
      <div className={styles.announcement}>Team Score: {teamScore}</div>
      <div className={styles.body}>
        {G.players.map((p) => (
          <div className={styles.playerContainer}>
            <div
              style={{
                width: 160,
                height: 160,
                margin: 21,
              }}
            >
              <UserIcon avatarUrl={p.avatarUrl} />
            </div>
            <div className={styles.playerName}>{p.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const ScoreRow = (r: {
  score: number;
  correctThisRound: boolean;
  avatarUrls: string[];
  index: number;
  highlight?: boolean;
  size?: 'md' | 'lg';
}) => {
  return (
    <div
      key={+r.avatarUrls}
      className={
        styles['scoring_row'] +
        ` ${r.highlight === true ? styles.highlight : ''} ${r.size === 'lg' ? styles.large : ''}`
      }
    >
      <div className={styles['scoring_row-body']}>
        {r.index === 0 && <div className={styles.crown} />}
        {r.avatarUrls.map((url, i) => (
          <div
            className={styles['scoring_row-icon']}
            style={{ left: (i - r.avatarUrls.length) * 57, position: 'absolute' }}
          >
            <UserIcon avatarUrl={url} key={url} />
          </div>
        ))}
        <div style={{ marginLeft: 30, fontSize: 32 }}>
          Score: {r.score} {r.correctThisRound && 'Correct!'}
        </div>
        <div style={{ position: 'absolute', right: 60, fontSize: 32 }}>{r.index + 1}</div>
      </div>
    </div>
  );
};
