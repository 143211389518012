import React, { useEffect, useState } from 'react';
import { useGameObject } from '../store/GameContext';
import { speakTextQueue } from '../textToSpeech';
import { track } from '@magicyard/shared/src/localAnalytics';
import { sanitizeForCompare } from '@magicyard/gptrivia-game/src/promptTypes';
import { QuestionBody } from './Stages/RevealSentence';
import { calcScore } from '@magicyard/gptrivia-game/src/utils/moves.util';
import styles from './ScoringTransition.module.css';

export const ScoringTransition = () => {
  const { G } = useGameObject();
  const choice = G.selectedAnswerForPromptIndex[G.promptIndex];
  const answer = choice?.answer;
  const choiceIndex =
    choice === undefined
      ? null
      : G.promptResults[G.promptIndex].options.map(sanitizeForCompare).indexOf(sanitizeForCompare(answer));
  const didGetItRight = answer === G.promptResults[G.promptIndex].correctAnswer;
  const earnedThisRound =
    choice?.answer === G.promptResults[G.promptIndex].correctAnswer
      ? calcScore(G, choice.timeTaken, G.promptIndex)
      : null;

  const [currentlyHighlighted, setCurrentlyHighlighted] = useState<number | null>(choiceIndex);
  const [showScore, setShowScore] = useState<boolean>(false);
  const correctAnswerIndex = G.promptResults[G.promptIndex].options
    .map(sanitizeForCompare)
    .indexOf(sanitizeForCompare(G.promptResults[G.promptIndex].correctAnswer));

  useEffect(() => {
    void speakTextQueue(
      [
        choice === undefined ? `The timer ran out without an agreement!` : `The team has made their choice!`,
        choice === undefined ? '' : `${didGetItRight ? `And got it right!` : 'And got it wrong'}.`,
        didGetItRight ? `winning ${earnedThisRound} points` : `the correct answer is:`,
        didGetItRight ? '' : G.promptResults[G.promptIndex].correctAnswer,
      ],
      (i) => {
        if (i === 1) {
          setShowScore(true);
        }
        if (i === 2) {
          setCurrentlyHighlighted(correctAnswerIndex);
        }
      }
    );
  }, []);

  useEffect(() => {
    track('Correct Answer Shown', { answer: G.promptResults[G.promptIndex].correctAnswer });
  }, []);

  return (
    <>
      {showScore && earnedThisRound !== null && (
        <div className={styles.points}>
          <div>+{earnedThisRound}</div>
        </div>
      )}
      <QuestionBody step={null} forceDisableExceptIndex={currentlyHighlighted} />
    </>
  );
};
