import { Quiz, QuizSession } from './dailyQuizTypes';
import { PromptLine } from './promptTypes';

interface Profile {
  id: string;
  name: string;
  avatarUrl: string;
}

import { generateTagsForDailyQuiz } from './prompt';
import { SimpleDailyQuiz } from './Types';
import axios from 'axios';

const QUIZ_API_URL = 'https://db-gptrivia.mgy.gg'; // 'http://localhost:8787';

function slugify(...args: (string | number)[]) {
  const value = args.join(' ');

  return value
    .normalize('NFD') // split an accented letter in the base letter and the accent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, '_'); // separator
}

export async function saveQuiz({
  creator,
  category,
  promptLines,
  sessions,
  isAutoGenerated,
  tags,
}: {
  creator: Profile;
  category: string;
  promptLines: PromptLine[];
  sessions?: QuizSession[];
  isAutoGenerated?: boolean;
  tags: string[];
}): Promise<string> {
  // Don't think we need to save stub quizzes anymore
  if (promptLines.length === 0) {
    return slugify(category);
  }

  const response = await axios.post(
    `${QUIZ_API_URL}/quizzes`,
    {
      creator,
      category,
      promptLines,
      tags,
    },
    { headers: { 'Content-Type': 'application/json' } }
  );

  return response.data.id;
}

export async function updatePlayedQuestion(qId: number) {}

export async function ratePlayedQuiz(playedQuizId: number, rating: number, playerId: string) {
  await axios.post(`${QUIZ_API_URL}/played-quizzes/${playedQuizId}/rate`, {
    rating,
    playerId,
  });
}

export async function getQuiz(quizId: number) {
  const response = await axios.get<Quiz>(`${QUIZ_API_URL}/quiz/${quizId}`);

  return response.data;
}

// TODO plug!
export async function getHighscores(quizId: number) {
  const response = await axios.get<QuizSession[]>(`${QUIZ_API_URL}/quiz/${quizId}/highscores`);

  return response.data;
}

export async function createQuizSession(quizId: number, controllerProfiles: Profile[]): Promise<number> {
  const x = await axios.post(`${QUIZ_API_URL}/played-quizzes`, {
    playedQuiz: {
      quizId: quizId,
      score: 0,
      playerProfiles: controllerProfiles,
    },
  });
  return x.data;
}

export async function updatePlayedQuizScore(playedQuizId: number, score: number): Promise<{ id: number }> {
  return await axios.put(`${QUIZ_API_URL}/played-quizzes/${playedQuizId}/score`, {
    score: score,
  });
}

export const getAllDailyQuizzes = async (controllerProfiles: Profile[]): Promise<SimpleDailyQuiz[]> => {
  const response = await axios.post<SimpleDailyQuiz[]>(`${QUIZ_API_URL}/quizzes/simple`, {
    controllerIds: controllerProfiles.map((x) => x.id),
  });

  return response.data;
};
