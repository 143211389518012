import React, { useEffect, useState } from 'react';
import { useGameObject } from '../store/GameContext';
import useInterval from '../hooks/useInterval';
import { speakText, stopSpeaking } from '../textToSpeech';
import styles from './Explainer.module.css';
import { Mascot } from '../Mascot';
import { UNPICKED_CATEGORY } from '@magicyard/gptrivia-game/src/Game';

export const Explainer = () => {
  const { G, moves } = useGameObject();
  const explainerStrings = [
    `Team up and compete against like-minded squads
from across the globe.`,
    G.category === UNPICKED_CATEGORY
      ? `Use your mobile to search for a trivia quiz on any topic\nimaginable, and if it's not there, craft one instantly!`
      : `Get ready to show off your expertise on ${G.category}!`,
  ];
  const [wordIndex, setWordIndex] = useState(0);
  const [sentenceIndex, setSentenceIndex] = useState(G.didFailPrompt ? explainerStrings.length : -1);
  const titleString = `Welcome ${G.players
    .map((p, i) => {
      if (i === G.players.length - 1) {
        if (G.players.length > 1) {
          return 'and ' + p.name;
        }
        return p.name;
      }
      return p.name + ',';
    })
    .join(' ')}`;
  const didFinish = sentenceIndex > explainerStrings.length;
  useInterval(() => {
    if (sentenceIndex === -1 || didFinish) {
      return;
    }
    setWordIndex((old) => old + 1);
  }, 50);
  useEffect(() => {
    if (!G.didFailPrompt) {
      speakText(titleString, () => setSentenceIndex(0));
    } else {
      speakText("The last topic you tried didn't work, try another.");
    }
    return () => {
      stopSpeaking();
    };
  }, [G.didFailPrompt]);

  useEffect(() => {
    if (sentenceIndex === -1 || didFinish) {
      return;
    }
    speakText(explainerStrings[sentenceIndex], () => {
      if (sentenceIndex === explainerStrings.length - 1) {
        moves.finishedTalking();
        return;
      }
      setSentenceIndex((old) => old + 1);
      setWordIndex(0);
    });
  }, [sentenceIndex]);
  return (
    <div className={styles.root}>
      <Mascot
        direction={sentenceIndex === -1 ? 'left' : 'right'}
        absPos={{
          right: sentenceIndex === -1 ? 150 : 1500,
          bottom: sentenceIndex === -1 ? 450 : sentenceIndex === 0 ? 500 : sentenceIndex === 1 ? 300 : 220,
        }}
      />
      <div className={styles.title} style={{ top: sentenceIndex === -1 ? 400 : 270 }}>
        {titleString}
      </div>
      <div className={styles.body}>
        {sentenceIndex === -1
          ? null
          : explainerStrings.map((s, i) => {
              if (i > sentenceIndex) {
                return '';
              } else if (i < sentenceIndex) {
                return (
                  <div key={s}>
                    {explainerStrings[i]}
                    <br />
                    <br />
                  </div>
                );
              } else {
                return <div>{explainerStrings[sentenceIndex].slice(0, wordIndex)}</div>;
              }
            })}{' '}
        <br />
        {G.didFailPrompt && "The last topic you tried didn't work, try another."}
      </div>
    </div>
  );
};
