import { Phases } from './Game';

const ms = 1000;
export const SYNC_MS = 10 * ms;
export const GAME_END = 60 * ms;
export const TIME_TO_GUESS = 30 * ms;
export const waitTimeForPhaseInMs: Record<Phases, number> = {
  Sync: SYNC_MS,
  [Phases.Explainer]: 1000 * ms * ms,
  [Phases.PickCategory]: 1000 * ms * ms,
  [Phases.Prompting]: 1000 * ms,
  [Phases.ViewingHighScore]: 1000 * ms * ms,
  Playing: 1000 * ms,
  Scoring: 5 * ms,
  gameEnd: GAME_END * 10000,
};

export const transitionTimeForPhasesInMs: Record<Phases, number | null> = {
  Sync: null,
  [Phases.ViewingHighScore]: null,
  [Phases.Explainer]: null,
  [Phases.PickCategory]: null,
  [Phases.Prompting]: null,
  Playing: null,
  Scoring: 9 * ms,
  gameEnd: null,
};
