import { Polly } from 'aws-sdk';
import { Howl, Howler } from 'howler';
import { lastFocus } from '@magicyard/shared/src/UseNativeFocus';
import { volumeAdjust } from './AudioManager';

let beingSpoken: Howl | null = null;

const polly = new Polly({
  region: 'us-east-1', // Replace with your desired AWS region
  accessKeyId: 'AKIAUMAO7FKYHSS7EEGP', // Replace with your AWS access key ID
  secretAccessKey: 'sbPO8xE4Ovi7ClH5Ju76pOH9U+vjMovKmotM7QIe', // Replace with your AWS secret access key
});

const getParams = (text: string) => {
  return {
    OutputFormat: 'mp3',
    Text: text,
    VoiceId: 'Joanna',
  };
};

const prepTextForSpeech = (text: string) => {
  const emojiRegex = /[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu;
  return text.replace(emojiRegex, '');
};

export async function speakText(rawText: string, onEnded?: () => void, doNotAdjustVolume?: boolean) {
  const text = prepTextForSpeech(rawText);

  const volumeAdjustIfNeeded = (volume: number) => {
    if (doNotAdjustVolume !== true) {
      volumeAdjust(volume);
    }
  };

  try {
    const response = await polly.synthesizeSpeech(getParams(text)).promise();
    const audioData = (await response.AudioStream) as Buffer;

    const audioBlob = new Blob([audioData], { type: 'audio/mpeg' });
    beingSpoken?.stop();
    beingSpoken = new Howl({ src: [URL.createObjectURL(audioBlob)], html5: true, volume: 1 });
    if (lastFocus) {
      beingSpoken.play();
      volumeAdjustIfNeeded(0.1);
    }
    // Check to see if it actually started playing
    setTimeout(() => {
      if (!beingSpoken?.playing()) {
        volumeAdjustIfNeeded(0.6);
        onEnded?.();
      }
    }, 300);
    beingSpoken.once('end', () => {
      volumeAdjustIfNeeded(0.6);
      onEnded?.();
    });
  } catch (err) {
    console.error('Error:', err);
    volumeAdjustIfNeeded(0.6);
    onEnded?.();
  }
}

export async function speakTextQueue(
  texts: string[],
  onPop?: (index: number) => void,
  onEnd?: () => void,
  startIndex: number = 0
) {
  volumeAdjust(0.1);
  if (startIndex === texts.length) {
    onEnd?.();
    volumeAdjust(0.6);
    return;
  }
  const next = () => {
    onPop?.(startIndex);
    speakTextQueue(texts, onPop, onEnd, startIndex + 1);
  };

  try {
    speakText(texts[startIndex], next, true);
  } catch (e) {
    console.log(e);
    next();
  }
}

export const stopSpeaking = () => {
  beingSpoken?.stop();
  beingSpoken = null;
};
