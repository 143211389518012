import React, { useCallback, useEffect, useState } from 'react';
import { Phases } from '@magicyard/gptrivia-game/src/Game';
import Theme from '../assets/audio/theme.mp3';
import PlayingMusic from '../assets/audio/playing.mp3';
import QuestionMusic from '../assets/audio/question.mp3';
import AnswerCorrectMusic from '../assets/audio/answerCorrect.mp3';
import Explainer from '../assets/audio/explainer.mp3';
import { Howl, Howler } from 'howler';
import { lastFocus, useNativeFocus } from '@magicyard/shared/src/UseNativeFocus';

const searchParams = new URLSearchParams(window.location.search);
export const DISABLE_AUDIO = searchParams.get('disable_sound') === '1';
const html5Fallback = searchParams.get('version_name')?.toLowerCase().includes('android') === true;

if (html5Fallback) {
  Howler.usingWebAudio = false;
}

type HowlCompatible = {
  pause: () => void;
  stop: () => void;
  play: () => void;
  playing: () => boolean;
  volume: (v?: number) => number;
};

const getHowlCompatible = (audio: HTMLAudioElement): HowlCompatible => {
  return {
    play: audio.play.bind(audio),
    pause: audio.pause.bind(audio),
    stop: () => {
      audio.pause();
      audio.currentTime = 0;
    },
    playing: () => !audio.paused,
    volume: (vol?: number) => {
      if (vol !== undefined) {
        audio.volume = vol;
      }
      return audio.volume;
    },
  };
};

const newMusic = (src: string) => {
  if (DISABLE_AUDIO) {
    return null;
  }
  if (html5Fallback) {
    const audio = new Audio(src);
    audio.loop = true;
    return getHowlCompatible(audio);
  }

  return new Howl({ src: [src], loop: true, volume: 0.3, html5: html5Fallback });
};

const newOneshot = (src: string) => {
  if (DISABLE_AUDIO) {
    return null;
  }
  if (html5Fallback) {
    const audio = new Audio(src);
    return getHowlCompatible(audio);
  }
  return new Howl({ src: [src], html5: html5Fallback });
};

export const questionMusic = newMusic(QuestionMusic);
export const playingMusic = newMusic(PlayingMusic);

const themeRef = newMusic(Theme);
export const AudioStagesMap: { [key in Phases]: HowlCompatible | null } = {
  [Phases.Sync]: themeRef,
  [Phases.Explainer]: newMusic(Explainer),
  [Phases.PickCategory]: themeRef,
  [Phases.Prompting]: themeRef,
  [Phases.ViewingHighScore]: themeRef,
  [Phases.Playing]: null,
  [Phases.Scoring]: newOneshot(AnswerCorrectMusic),
  [Phases.GameEnd]: newOneshot(AnswerCorrectMusic),
};

let volumeTarget = 0.3;

export const contiuouslyAdjustVolume = () => {
  const lerpVol = (a: HowlCompatible | null) => {
    if (a === null) {
      return;
    }
    const curr = a.volume();
    a.volume(curr + (volumeTarget - curr) * 0.1);
  };
  setInterval(() => {
    if (DISABLE_AUDIO) {
      return;
    }
    Object.values(AudioStagesMap).forEach((x) => {
      lerpVol(x);
    });
    lerpVol(menuMusic);
    lerpVol(questionMusic);
    lerpVol(playingMusic);
  }, 100);
};
contiuouslyAdjustVolume();

export const volumeAdjust = (volume: number) => {
  if (DISABLE_AUDIO) {
    return;
  }
  volumeTarget = volume;
  //   Object.values(AudioStagesMap).forEach((x) => {
  //     x?.volume(volume);
  //   });
  //   menuMusic?.volume(volume);
  //   questionMusic?.volume(volume);
  //   playingMusic?.volume(volume);
};

(window as any).volumeAdjust = volumeAdjust;

export const menuMusic = newMusic(Theme);

export const AudioManager = ({ phase, isTransition }: { phase: Phases; isTransition: boolean }) => {
  if (DISABLE_AUDIO) {
    return <></>;
  }
  return <AudioManagerInner phase={phase} isTransition={isTransition} />;
};

export const AudioManagerInner = ({ phase, isTransition }: { phase: Phases; isTransition: boolean }) => {
  const [activeSoundTrack, setActiveSoundTrack] = useState<Phases | null>(null);

  const onBlur = useCallback(() => {
    playAudioIfFocused(false, phase);
    AudioStagesMap[phase]?.pause();
  }, [phase]);
  const onFocus = useCallback(() => {
    if (AudioStagesMap[phase]?.playing() === false) {
      playAudioIfFocused(true, phase);
      AudioStagesMap[phase]?.play();
    }
  }, [phase]);

  useNativeFocus(onFocus, onBlur);
  const playAudioIfFocused = useCallback((isFocused: boolean, phase: Phases) => {
    const curr = AudioStagesMap[phase];
    if (isFocused && curr !== null) {
      return curr.play();
    } else {
      return new Promise(() => undefined);
    }
  }, []);

  const turnVolumeDown = useCallback((target: Phases) => {
    const curr = AudioStagesMap[target];
    if (curr === null) {
      return;
    }
    curr.stop();
  }, []);

  useEffect(() => {
    const shouldPlayNewTrack = activeSoundTrack !== phase && lastFocus;
    const shouldStopOldTrack = activeSoundTrack !== null && shouldPlayNewTrack;
    if (shouldStopOldTrack) {
      try {
        turnVolumeDown(activeSoundTrack);
      } catch (err) {
        console.log(err);
      }
    }
    if (shouldPlayNewTrack) {
      setActiveSoundTrack(phase);
    }
  }, [activeSoundTrack, isTransition, phase, turnVolumeDown]);

  useEffect(() => {
    if (AudioStagesMap[phase]) {
      playAudioIfFocused(lastFocus, phase);
    } else console.log('Could Not find sound track');
  }, [activeSoundTrack, playAudioIfFocused, phase]);
  return null;
};

export const stopMusic = () => {
  Object.values(AudioStagesMap).map((x) => x?.pause());
  menuMusic?.pause();
};
