import { getMultiplayerMode } from '@magicyard/utils';
import Board from './components/Board';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { usePlatformDisplay } from '@magicyard/shared/platform/hooks/usePlatformDisplay';
import { YardWithGame } from '@magicyard/shared/platform/lib/api';
import { Background } from './components/Background/Background';
import './index.css';
import { useFullscreen } from '@magicyard/shared/platform/hooks/useTryFullscreen';
import useForceScale from '@magicyard/shared/platform/hooks/useForceScale';
import { YardAndDisplay } from './YardAndDisplay';
import { CONFIG } from '@magicyard/utils';
import { initAnalytics, track, updateAnalyticsData } from '@magicyard/shared/src/analytics';
import { InitialLoading } from './InitialLoading';
import { QueueScreen } from './QueueScreen';
import { menuMusic, stopMusic } from '@magicyard/gptrivia-display/src/AudioManager';
import { useNativeFocus } from '@magicyard/shared/src/UseNativeFocus';
import { StateConfigExternal } from '@magicyard/shared/platform/hooks/usePlatformDisplayTypes';
import { GPTrivia } from '@magicyard/gptrivia-game/src/Game';
import { MagicyardBgioClient } from '@magicyard/shared/src/MagicyardBgioClient';
import { stopSpeaking } from './textToSpeech';

const IS_DEBUG = true;

export interface AppProps {
  matchID: string;
}

export const GameLoader = ({ yard }: { yard: YardWithGame }) => {
  useEffect(() => {
    track('Game Loading Appeared');
  }, [yard]);

  return <InitialLoading />;
};

export const App: React.ReactNode = () => {
  useFullscreen();
  useForceScale('#root-gptrivia-display');

  const test = useRef<'A' | 'B'>(Math.random() > 0.5 ? 'A' : 'A');
  const lastYardId = useRef<string | undefined>(undefined);

  useEffect(() => {
    initAnalytics(CONFIG.MIXPANEL_API_KEY, 'Display', 'Standalone', 'gptrivia');
    updateAnalyticsData({ abTest: test.current === 'A' ? 'A - QR screen - Original' : 'B - QR screen - TV Phone' });
    track(`App Launched`);
  }, []);

  const query = new URLSearchParams(window.location.search);
  // Force click on none native devices
  const [forceClickBeforeStart, setForceClickBeforeStart] = useState(query.get('version_code') === null);

  const child = usePlatformDisplay<
    {
      children: React.ReactNode;
      position: number | null;
      renderPosition?: number;
    },
    { url: string }
  >(
    {
      onLoading() {
        return {
          children: <InitialLoading key={1} />,
          position: 0,
        };
      },
      onYardAndDisplay({ yard, display }) {
        updateAnalyticsData({ numDisplaysConnected: yard.displays?.length ?? 0, yardId: yard.id });
        if (lastYardId.current !== undefined && lastYardId.current !== yard.id) {
          track('Display Changed Yard');
          lastYardId.current = yard.id;
        }

        return {
          position: forceClickBeforeStart ? 0 : yard.controllers.length === 0 ? 1 : test.current === 'A' ? 1.2 : 1.69,
          renderPosition: forceClickBeforeStart ? 0 : 1,
          children: forceClickBeforeStart ? (
            <InitialLoading key={1} onClick={() => setForceClickBeforeStart(false)} />
          ) : (
            <YardAndDisplay yard={yard} display={display} test={test.current} />
          ),
        };
      },
      onLoadingGame({ yard }) {
        return { position: 0, children: <GameLoader yard={yard} /> };
      },
      onQueue({ yard, display }) {
        return { position: 1.7, children: <QueueScreen display={display} yard={yard} /> };
      },
      onGame(params) {
        return { children: <OnGame {...params} />, position: null };
      },
    },
    { displayId: query.get('displayId') },
    'gptrivia'
  );

  useNativeFocus(
    () => {
      if (!forceClickBeforeStart && child.position !== null) {
        menuMusic?.play();
      }
    },
    () => {
      menuMusic?.pause();
    }
  );

  useEffect(() => {
    if (!forceClickBeforeStart && child.position !== null) {
      menuMusic?.play();
    } else if (child.position === null) {
      menuMusic?.pause();
    }
  }, [child.position, forceClickBeforeStart]);

  if (child.position === null) {
    return child.children;
  }

  return <Background>{child.children}</Background>;
};

export const OnYardAndDisplayExternal: StateConfigExternal<JSX.Element, any>['onYardAndDisplay'] = ({
  yard,
  display,
}) => {
  return (
    <div className={'root-gptrivia-display'}>
      <Background>
        <YardAndDisplay yard={yard} display={display} test={'A'} />
      </Background>
    </div>
  );
};

export const OnGameExternal: StateConfigExternal<JSX.Element, any>['onGame'] = ({
  yard,
  display,
  onBack,
  communication,
  gameStartArgs,
}) => {
  return (
    <div className={'root-gptrivia-display'}>
      <OnGame
        yard={yard}
        gameStartArgs={gameStartArgs}
        display={display}
        communication={communication}
        onBack={onBack}
      />
    </div>
  );
};

const OnGame: StateConfigExternal<JSX.Element, any>['onGame'] = ({ gameStartArgs, display, onBack }) => {
  const urlParams = new URLSearchParams(new URL(gameStartArgs.url).search);
  const matchID = urlParams.get('matchID') || process.env.REACT_APP_MATCH_ID || 'default';
  const serverURL = urlParams.get('serverURL') ?? undefined;
  useEffect(() => {
    track('Game Started');
    return () => {
      stopMusic();
      stopSpeaking();
    };
  }, []);
  const clientOpts = useMemo(
    () => ({
      loading: () => (
        <Background>
          <InitialLoading />
        </Background>
      ),
      board: Board,
      debug: IS_DEBUG,
      multiplayer: getMultiplayerMode(serverURL),
      game: GPTrivia,
    }),
    [serverURL]
  );

  const boardProps = useMemo(
    () => ({
      matchID: matchID,
      playerID: '0',
      display: display,
      onBack: onBack,
    }),
    [matchID, display, onBack]
  );

  return <MagicyardBgioClient clientOpts={clientOpts} boardProps={boardProps} />;
};

const SAMSUNG_BACK_KEY_CODE = 10009;

export const useSamsungBack = (msg: string, onBack: () => void) => {
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.keyCode === SAMSUNG_BACK_KEY_CODE) {
        if (window.confirm(msg)) {
          onBack();
        }
      }
    };
    window.addEventListener('keydown', listener);
    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [onBack]);
};
