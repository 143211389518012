import { INVALID_MOVE } from 'boardgame.io/core';
import { getInitialPromptState, getSettingsForPromptIndex, Phases } from '@magicyard/gptrivia-game/src/Game';
import { CtxWithApi, GameState, PowerUpsType, SimpleDailyQuiz } from '../Types';
import { assertIsDefined, assertNever } from './typeUtils';
import { objectKeys } from '@magicyard/gptrivia-shared/utils';
import { PlayerID } from 'boardgame.io';
import { PromptLine, sanitizeForCompare } from '../promptTypes';
import { DailyQuizOption, QuizSession } from '../dailyQuizTypes';
import { shuffleArray } from './common.util';

const endReveal = (G: GameState, ctx: CtxWithApi) => {
  console.log(ctx.playerID);
  if (ctx.playerID === '0') {
    G.endedRevealingLastSentenceAt = Date.now();
    return undefined;
  }
  return INVALID_MOVE;
};
const chooseCategory = (
  G: GameState,
  ctx: CtxWithApi,
  category: string,
  metaData: { quizId: number; highScore: QuizSession | null } | null,
  didAlreadyPlay?: boolean
) => {
  G.category = category;
  G.quizId = metaData?.quizId ?? null;
  G.highScore = metaData?.highScore ?? null;
  if (didAlreadyPlay === true) {
    ctx.events.setPhase(Phases.ViewingHighScore);
    return undefined;
  }

  const s = getInitialPromptState(G.players);
  G.playerIdWithPromptIndexToAnswer = s.playerIdWithPromptIndexToAnswer;
  G.promptIndex = s.promptIndex;
  G.promptResults = s.promptResults;
  G.didFailPrompt = s.didFailPrompt;
  ctx.events.endPhase();

  return undefined;
};

const failPrompt = (G: GameState, ctx: CtxWithApi) => {
  if (isMoveMadeByMasterClient(ctx)) {
    G.didFailPrompt = true;
    return undefined;
  }

  return INVALID_MOVE;
};

const setPromptResult = {
  move: (G: GameState, ctx: CtxWithApi, promptResult: PromptLine[]) => {
    if (isMoveMadeByMasterClient(ctx)) {
      G.promptResults = G.promptResults.concat(promptResult);
      return undefined;
    }
    return INVALID_MOVE;
  },
  ignoreStaleStateID: true,
};

const setPlayedQuizId = {
  move: (G: GameState, ctx: CtxWithApi, id: number) => {
    if (isMoveMadeByMasterClient(ctx)) {
      G.playedQuizId = id;
      return undefined;
    }
    return INVALID_MOVE;
  },
  ignoreStaleStateID: true,
};

const endSync = (G: GameState, ctx: CtxWithApi) => {
  console.log('ENDING SYNC PHASE');
  console.log(ctx.playerID);
  // Temp, the screen has the same playerId as the first player
  if (ctx.playerID === '0') {
    ctx.events.endPhase();
    return undefined;
  }
  return INVALID_MOVE;
};

// Simultaneous guessing.
const forceEndPhase = (G: GameState, ctx: CtxWithApi) => {
  console.log(`Phase ${ctx.phase} was forced to end`);
  if (!isMoveMadeByMasterClient(ctx)) {
    return INVALID_MOVE;
  }
  const phase = ctx.phase as Phases;
  switch (phase) {
    case Phases.Sync:
      ctx.events.endPhase();
      break;
    case Phases.Playing:
    case Phases.Explainer:
    case Phases.Prompting:
    case Phases.PickCategory:
      // No timer
      break;
    case Phases.Scoring:
      G.promptIndex++;
      ctx.events.endPhase();
      break;
    case Phases.ViewingHighScore:
    case Phases.GameEnd:
      // Last phase, do nothing
      break;
    default:
      assertNever(phase);
  }
  return undefined;
};

const isMoveMadeByMasterClient = (ctx: CtxWithApi) => +ctx.playerID === ctx.numPlayers - 1;

const timesUp = {
  move: (G: GameState, ctx: CtxWithApi) => {
    console.log(`Player ${ctx.playerID} called timesUp on ${ctx.phase}`);

    if (!isMoveMadeByMasterClient(ctx)) {
      return INVALID_MOVE;
    }
    const phase = ctx.phase as Phases;
    switch (phase) {
      case Phases.Playing:
      case Phases.Sync:
        ctx.events.endPhase();
        break;
      case Phases.Explainer:
      case Phases.Prompting:
      case Phases.PickCategory:
        // No timer
        break;
      case Phases.Scoring:
        G.promptIndex++;
        ctx.events.endPhase();
        break;
      case Phases.ViewingHighScore:
      case Phases.GameEnd:
        // Last phase, do nothing
        break;
      default:
        assertNever(phase);
    }

    return undefined;
  },
  ignoreStaleStateID: true,
};

const transitionTimeUp = (G: GameState, ctx: CtxWithApi) => {
  console.log(`Player ${ctx.playerID} ended the transiton for ${ctx.phase}`);
  if (!isMoveMadeByMasterClient(ctx)) {
    return INVALID_MOVE;
  }
  G.isTransition = false;
  return undefined;
};

const selectAnswer = {
  move: (G: GameState, ctx: CtxWithApi, answer: string) => {
    assertIsDefined(G.endedRevealingLastSentenceAt);
    const selectedAnswer = {
      answer: answer,
      timeTaken: Date.now() - G.endedRevealingLastSentenceAt,
      promptIndex: G.promptIndex,
    };

    G.playerIdWithPromptIndexToAnswer[ctx.playerID][G.promptIndex] = selectedAnswer;

    const playerSelectedThisAnswerCount = G.players.filter(
      (p) => G.playerIdWithPromptIndexToAnswer[p.id][G.promptIndex]?.answer === answer
    ).length;

    const didMajoritySelectThisAnswer = playerSelectedThisAnswerCount > Math.floor(G.players.length / 2);
    if (didMajoritySelectThisAnswer) {
      G.selectedAnswerForPromptIndex[G.promptIndex] = selectedAnswer;
      ctx.events.endPhase();
    }
    return undefined;
  },
  ignoreStaleStateID: true,
};
export const getOptionsWithDisabled = (G: GameState) => {
  const usedPowerUp = G.powerUpsUsedForPromptIndex[G.promptIndex];
  const fiftyFifty = usedPowerUp?.type === 'fiftyFifty' ? usedPowerUp : null;
  return G.promptResults[G.promptIndex].options.map<{
    option: string;
    i: number;
    selectedBy: PlayerID[] | undefined;
    disabled: boolean;
  }>((o, i) => {
    const selectedBy = Object.keys(G.playerIdWithPromptIndexToAnswer).filter((k) => {
      return o === G.playerIdWithPromptIndexToAnswer[k][G.promptIndex]?.answer;
    });
    return {
      option: o,
      i: i,
      selectedBy: selectedBy.length === 0 ? undefined : selectedBy,
      disabled: fiftyFifty?.disabledIndexes.includes(i) ?? false,
    };
  });
};

export const getUnpickedOptions = (G: GameState) => {
  return G.promptResults[G.promptIndex].options.reduce<{ o: string; i: number }[]>((acc, o, i) => {
    if (
      !objectKeys(G.playerIdWithPromptIndexToAnswer).some(
        (k) => o === G.playerIdWithPromptIndexToAnswer[k][G.promptIndex]?.answer
      )
    ) {
      acc.push({ o: o, i: i });
    }
    return acc;
  }, []);
};

export const getTotalScore = (G: GameState) => {
  return Object.keys(G.selectedAnswerForPromptIndex).reduce((acc, index) => {
    const selectedAnswer = G.selectedAnswerForPromptIndex[+index];
    if (
      selectedAnswer !== undefined &&
      selectedAnswer !== null &&
      G.promptResults[+index].correctAnswer === selectedAnswer?.answer
    ) {
      acc += calcScore(G, selectedAnswer.timeTaken, selectedAnswer.promptIndex);
    }

    return acc;
  }, 0);
};

// const receiveDailyQuizzes = {
//   move: (G: GameState, ctx: CtxWithApi, dailyQuizzes: SimpleDailyQuiz[]): void => {
//     G.dailyQuizzesTopics = dailyQuizzes;
//     if (G.waitForDailyQuizzes) {
//       const preselected = G.dailyQuizzesTopics.find((x) => x.category === G.category);
//       if (preselected !== undefined) {
//         if (getDidGroupAlreadyPlay(preselected)) {
//           G.selectedQuizData = { quizId: preselected.quizId, highScores: [] }; // TODO
//           ctx.events.setPhase(Phases.ViewingHighScore);
//           return;
//         } else {
//           G.selectedQuizData = { quizId: preselected.quizId, highScores: [] }; // TODO
//           // TODO
//           // @ts-ignore
//           G.promptResults = preselected.content;
//         }
//       }
//       // ctx.events.endPhase();
//     }
//   },
//   ignoreStaleStateID: true,
// };

const MAX_USES_OF_POWER_UP = 1;
export const canUsePowerUp = (G: GameState, powerUp: PowerUpsType) => {
  const useCountForPowerUp = objectKeys(G.powerUpsUsedForPromptIndex).reduce<{ [key in PowerUpsType]: number }>(
    (acc, k) => {
      acc[G.powerUpsUsedForPromptIndex[k].type]++;
      return acc;
    },
    { double: 0, fiftyFifty: 0 }
  );

  return (
    G.powerUpsUsedForPromptIndex[G.promptIndex] === undefined && useCountForPowerUp[powerUp] < MAX_USES_OF_POWER_UP
  );
};

const usePowerUp = {
  move: (G: GameState, ctx: CtxWithApi, powerUp: PowerUpsType) => {
    if (!canUsePowerUp(G, powerUp)) {
      return INVALID_MOVE;
    }
    switch (powerUp) {
      case 'double':
        G.powerUpsUsedForPromptIndex[G.promptIndex] = { type: 'double' };
        break;
      case 'fiftyFifty': {
        const twoRandomWrongAnswerIndexes = shuffleArray(
          G.promptResults[G.promptIndex].options.map((o, i) => ({
            option: o,
            i: i,
          }))
        ).reduce<number[]>((acc, x, i) => {
          if (x.option !== G.promptResults[G.promptIndex].correctAnswer && acc.length < 2) {
            acc.push(x.i);
          }
          return acc;
        }, []);
        G.powerUpsUsedForPromptIndex[G.promptIndex] = {
          type: 'fiftyFifty',
          disabledIndexes: twoRandomWrongAnswerIndexes,
        };
        break;
      }
      default:
        assertNever(powerUp);
    }
    return undefined;
  },
  client: false,
};

export const getDidGroupAlreadyPlay = (d: SimpleDailyQuiz | DailyQuizOption) => {
  return d.playerSessions !== undefined && d.playerSessions.length > 0;
};

export const calcScore = (G: GameState, timeTakenMs: number, promptIndex: number) => {
  const double = G.powerUpsUsedForPromptIndex[promptIndex]?.type === 'double';
  const roundSettings = getSettingsForPromptIndex(promptIndex);
  const calcedScore = Math.round(
    roundSettings.initialScore - (timeTakenMs / 1000) * (roundSettings.lossPerSecond ?? 0)
  );
  return calcedScore * (double ? 2 : 1);
};

export const saveQuizAfterCreation = {
  move: (G: GameState, ctx: CtxWithApi, id: number) => {
    console.log('SAVE QUIZ AFTER CREATION!!');
    G.quizId = id;
  },
  ignoreStaleStateID: true,
};

export const movesUtil = {
  setPlayedQuizId,
  endSync,
  timesUp,
  forceEndPhase,
  transitionTimeUp,
  chooseCategory,
  setPromptResult,
  selectAnswer,
  endReveal,
  failPrompt,
  saveQuizAfterCreation,
  usePowerUp,
};
export const DAILY_CHALLENGE_CATEGORY = 'General Trivia';
export const DAILY_CHALLENGE_ID = 'general_trivia';
