import React, { useEffect, useRef, useState } from 'react';
import { useGameObject } from '../../store/GameContext';
import { speakText, speakTextQueue } from '../../textToSpeech';
import {
  calcScore,
  DAILY_CHALLENGE_CATEGORY,
  getOptionsWithDisabled,
} from '@magicyard/gptrivia-game/src/utils/moves.util';
import { GameButtonWithUser } from '@magicyard/gptrivia-shared/components/GameButton/GameButtonWithUser';
import styles from './RevealSentence.module.css';
// @ts-ignore
import CanvasCircularCountdown from 'canvas-circular-countdown';
import { assertIsDefined, assertNever } from '@magicyard/gptrivia-game/src/utils/typeUtils';
import { getSettingsForPromptIndex } from '@magicyard/gptrivia-game/src/Game';
import { playingMusic, questionMusic } from '../../AudioManager';
import { useNativeFocus } from '@magicyard/shared/src/UseNativeFocus';
import { DESIRED_AMOUNT_OF_QUESTIONS } from '@magicyard/gptrivia-game/src/promptTypes';
import { Mascot } from '../../Mascot';
import useInterval from '../../hooks/useInterval';
import { track } from '@magicyard/shared/src/localAnalytics';
import { Textfit } from 'react-textfit';
import { ScoreRow } from '../Scoring';
import { TIME_TO_GUESS } from '@magicyard/gptrivia-game/src/Config';

export const RevealSentence = () => {
  const { G, moves, ctx } = useGameObject();

  const [step, setStep] = useState(0);
  const scoreRef = useRef<HTMLDivElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [timer, setTimer] = useState<any>(null);
  const settings = getSettingsForPromptIndex(G.promptIndex);
  const [showGetReady, setShowGetReady] = useState(G.promptIndex === 0);
  const [countdown, setCountdown] = useState(settings.showCountdown ? 4 : null);
  const [powerUpText, setPowerUpText] = useState<string | null>(null);
  const [reminderText, setReminderText] = useState<string | null>(null);
  const didUsePowerUp = G.powerUpsUsedForPromptIndex[G.promptIndex]?.type;

  const pickAnswerCount = G.players.filter((p) => {
    return G.playerIdWithPromptIndexToAnswer[p.id][G.promptIndex] !== undefined;
  }).length;

  useEffect(() => {
    if (pickAnswerCount === 0 || reminderText !== null) {
      return;
    }
    const id = window.setTimeout(() => {
      setReminderText(`The team must agree on an answer!`);
      speakText('The team must agree on an answer!');
    }, 4000);
    return () => {
      window.clearTimeout(id);
    };
  }, [pickAnswerCount, reminderText]);

  useEffect(() => {
    if (didUsePowerUp !== undefined && powerUpText === null) {
      switch (didUsePowerUp) {
        case 'double':
          setPowerUpText('Double or Nothing!');
          speakText('Double or nothing activated!');
          break;
        case 'fiftyFifty':
          setPowerUpText('50 : 50!');
          speakText('Fifty Fifty activated!');
          break;
        default:
          assertNever(didUsePowerUp);
      }
    }
  }, [didUsePowerUp, powerUpText]);

  const getPassedTimeMs = () =>
    G.endedRevealingLastSentenceAt === null ? 0 : Date.now() - G.endedRevealingLastSentenceAt;

  useInterval(() => {
    if (scoreRef.current !== null && timer !== null) {
      scoreRef.current.innerHTML = '' + Math.max(calcScore(G, getPassedTimeMs(), G.promptIndex));
    }
  }, 50);

  useNativeFocus(
    () => {
      questionMusic?.pause();
      playingMusic?.pause();
    },
    () => undefined
  );
  const isCountingDown = countdown !== null && !showGetReady;
  const subtitleForSettings = `Correct answers are worth ${settings.initialScore} points.\n${
    settings.lossPerSecond === null ? '' : `-${settings.lossPerSecond} for every second passed`
  }`;
  useEffect(() => {
    if (showGetReady) {
      speakText(
        G.category === DAILY_CHALLENGE_CATEGORY
          ? `Get ready for the daily challenge!,`
          : `Get ready for a quiz about ${G.category}!`,
        () => {
          setShowGetReady(false);
        }
      );
      return;
    }
    if (isCountingDown) {
      speakTextQueue(
        [`Round ${settings.round}! ${subtitleForSettings}. Starting in`, `3`, `2`, `1`],
        () => {
          setCountdown((old) => (old === null ? null : old - 1));
        },
        () => {
          setCountdown(null);
        }
      );
      return;
    }

    let id: undefined | number;
    const curr = G.promptResults[G.promptIndex];
    questionMusic?.play();
    speakTextQueue(
      [
        curr.question,
        'is it ' + curr.options[0] + '?',
        curr.options[1] + '?',
        curr.options[2] + '?',
        ', or, ' + curr.options[3] + '.',
      ],
      (i) => setStep(i + 1),
      () => {
        track('Question shown', { question: curr.question, answer: curr.correctAnswer });
        if (questionMusic !== null) {
          questionMusic.stop();
        }
        playingMusic?.play();
        moves.endReveal();
        assertIsDefined(canvasRef.current);
        id = window.setTimeout(moves.displayTimesUpPlaying, TIME_TO_GUESS - getPassedTimeMs());
        const t = new CanvasCircularCountdown(canvasRef.current, {
          duration: TIME_TO_GUESS,
          elapsedTime: getPassedTimeMs(),
          throttle: 1000,
          radius: 100,
          progressBarWidth: 10,
          progressBarOffset: 5,
          circleBackgroundColor: 'transparent',
          emptyProgressBarBackgroundColor: '#1084e8',
          filledProgressBarBackgroundColor: '#b2f9ff',
          showCaption: true,
          captionColor: 'white',
          captionText: (percentage: number) => {
            return Math.round((percentage * TIME_TO_GUESS) / 100 / 1000);
          },
          captionFont: 'small-caps bold 42px/1 sans-serif',
        });

        t.start();
        setTimer(t);
      }
    );
    return () => {
      questionMusic?.pause();
      if (playingMusic !== null) {
        playingMusic.stop();
      }
      window.clearTimeout(id);
    };
  }, [isCountingDown, showGetReady]);

  const getReadyTitle = `${G.category.charAt(0).toUpperCase() + G.category.slice(1)} Quiz!`;
  if (showGetReady) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div className={styles.countdownTitle}>
          <Textfit forceSingleModeWidth={false} mode={'single'}>
            {getReadyTitle}
          </Textfit>
        </div>
        {G.highScore !== null && (
          <div className={styles.scoreRow}>
            <div className={styles.footerTitle}>Highscore</div>
            <div style={{ marginLeft: 100, zIndex: 1 }}>
              <ScoreRow
                score={G.highScore.score}
                correctThisRound={false}
                avatarUrls={G.highScore.playerProfiles.map((p) => p.avatarUrl)}
                index={0}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
  if (isCountingDown) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {countdown < 4 && (
          <div className={styles.countdown} style={{ position: 'absolute', top: 220 }}>
            {countdown}
          </div>
        )}
        <div className={styles.countdownTitle} style={{ marginTop: 50 }}>
          <Textfit forceSingleModeWidth={false} mode={'single'}>
            Round {settings.round}
          </Textfit>
          <Textfit style={{ width: 800, height: 180, marginTop: 50, whiteSpace: 'pre-line' }} max={38} mode={'multi'}>
            {subtitleForSettings}
          </Textfit>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <QuestionBody step={step} />
      {powerUpText !== null && <div className={styles.powerUpText}>{powerUpText}</div>}
      {reminderText !== null && <div className={styles.reminderText}>{reminderText}</div>}
      <div ref={(ref) => (scoreRef.current = ref)}></div>
      <canvas
        width={200}
        height={200}
        ref={(ref) => (canvasRef.current = ref)}
        className={styles['playing-timer'] + ' ' + (timer === null ? '' : styles['playing-timer-on'])}
      ></canvas>
    </div>
  );
};

export const Question = ({ step, initialIndex }: { step: 'initial' | 'active'; initialIndex?: number }) => {
  const { G } = useGameObject();
  const [index, setIndex] = useState(initialIndex ?? 0);
  useInterval(() => {
    if (index > G.promptResults[G.promptIndex].question.length) {
      return;
    }
    setIndex((old) => old + 1);
  }, 50);
  return (
    <>
      <div
        className={styles['reveal_sentence-question_container']}
        style={{ marginBottom: step === 'initial' ? -850 : 600 }}
      >
        <Mascot
          direction={step === 'initial' ? 'left' : 'right'}
          absPos={{ left: step === 'initial' ? 1000 : -300, top: step === 'initial' ? -150 : 200 }}
        />
        <div className={styles['reveal_sentence-question_title']}>
          Question {G.promptIndex + 1} / {DESIRED_AMOUNT_OF_QUESTIONS}
        </div>
        <div className={styles['reveal_sentence-question_body']}>
          {G.promptResults[G.promptIndex].question.slice(0, index)}
        </div>
      </div>
    </>
  );
};

export const QuestionBody = ({
  step,
  forceDisableExceptIndex,
}: {
  step: number | null;
  forceDisableExceptIndex?: number | null;
}) => {
  const { G } = useGameObject();
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <div className={styles['playing-mask']}>
        <div
          style={{
            backgroundImage: `url(${G.promptResults[G.promptIndex].imageUrl})`,
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        ></div>
      </div>
      <Question key={'q'} step={step === 0 ? 'initial' : 'active'} initialIndex={step === null ? 10000 : 0} />
      <div className={styles['playing-options_container']}>
        {getOptionsWithDisabled(G).map((oi) => {
          if (step !== null && oi.i >= step) {
            return null;
          }
          return (
            <GameButtonWithUser
              animate={step !== null}
              maxFontSize={40}
              key={oi.i}
              i={oi.i}
              avatarUrl={oi.selectedBy === undefined ? undefined : oi.selectedBy.map((s) => G.players[+s].avatarUrl)}
              disable={
                oi.disabled ||
                forceDisableExceptIndex === null ||
                (forceDisableExceptIndex !== oi.i && forceDisableExceptIndex !== undefined)
              }
              text={oi.option}
              size={'lg'}
            />
          );
        })}
      </div>
    </div>
  );
};
