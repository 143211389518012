import React from 'react';
import styles from './UserIcon.module.css';

export const UserIcon = ({ avatarUrl, isGold }: { avatarUrl: string; isGold?: boolean }) => {
  return (
    <div className={`${styles.root} ${isGold === true ? styles.gold : ''}`}>
      <div className={styles.imageWithMask} style={{ backgroundImage: `url(${avatarUrl})` }} />
    </div>
  );
};
