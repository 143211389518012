import styles from './Mascot.module.css';
import React from 'react';

interface MascotProps {
  direction: 'left' | 'right';
  absPos?: { top?: number; left?: number; right?: number; bottom?: number };
}

export const Mascot = ({ direction, absPos }: MascotProps) => {
  return (
    <div className={styles['mascot-root_animation']} style={absPos}>
      <div
        className={styles['mascot-root']}
        style={{ transform: direction === 'right' ? 'scaleX(-1)' : 'scaleX(1)' }}
      />
    </div>
  );
};
