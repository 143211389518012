import { UserIcon } from '../UserIcon';
import { GameButton } from './GameButton';
import React from 'react';
import './GameButtonWithUser.css';
import { Textfit } from 'react-textfit';

export const GameButtonWithUser = ({
  i,
  avatarUrl,
  text,
  size,
  onClick,
  disable,
  maxFontSize,
  animate,
}: {
  i: number;
  avatarUrl: string[] | undefined;
  text: string;
  size: 'lg' | 'sm';
  maxFontSize: number;
  onClick?: () => void;
  disable?: boolean;
  animate?: boolean;
}) => (
  <div className={avatarUrl !== undefined && avatarUrl.length > 0 ? 'game_button_with_user-selected' : ''}>
    <GameButton
      animate={animate}
      variant={i}
      size={size}
      disable={disable === undefined ? avatarUrl !== undefined : disable}
      onClick={onClick}
    >
      {avatarUrl?.map((u, i) => (
        <div
          className={`${animate !== false ? 'game_button_with_user-icon' : ''} game_button_with_user-icon_${size}`}
          key={u}
          style={{ left: (i - avatarUrl.length + 1) * (size === 'lg' ? 80 : 25), position: 'absolute' }}
        >
          <UserIcon avatarUrl={u} />
        </div>
      ))}
      <Textfit
        max={maxFontSize}
        mode={'multi'}
        style={{ width: '76%', height: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        {text}
      </Textfit>
    </GameButton>
  </div>
);
