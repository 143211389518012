import React, { useEffect, useState } from 'react';
import styles from './WinnerTransition.module.css';
import { ScoreRow } from '../Scoring';
import { getTotalScore } from '@magicyard/gptrivia-game/src/utils/moves.util';
import { useGameObject } from '../../store/GameContext';
import { track } from '@magicyard/shared/src/localAnalytics';
import { QuizSession } from '@magicyard/gptrivia-game/src/dailyQuizTypes';
import { speakText, stopSpeaking } from '../../textToSpeech';
import { Textfit } from 'react-textfit';
import { Phases } from '@magicyard/gptrivia-game/src/Game';
import { getHighscores } from '@magicyard/gptrivia-game/src/dailyQuizApi';

export const WinnerTransition = () => {
  const { G, ctx } = useGameObject();
  const results = getTotalScore(G);
  const [serverScores, setServerScores] = useState<Array<QuizSession & { highlight?: boolean }> | null>(null);
  useEffect(() => {
    setServerScores(null);
    const getScores = async () => {
      if (G.quizId === null) {
        setServerScores([]);
      } else {
        const scores = await getHighscores(G.quizId);
        setServerScores(scores);
      }
    };
    getScores();
  }, [G.quizId]);
  let index: number | null = null;
  let allResults: Array<QuizSession & { highlight?: boolean }> = [];
  if (serverScores !== null) {
    const currResult = {
      score: results,
      playerProfiles: G.players,
      endTimeEpoch: Date.now(),
      highlight: true,
    };
    const preExistingResultForTeam =
      serverScores.findIndex((session) =>
        session.playerProfiles.some((profile) => G.players.some((player) => profile.id === player.controller_id))
      ) ?? -1;
    if (preExistingResultForTeam !== -1) {
      serverScores[preExistingResultForTeam] = { ...serverScores[preExistingResultForTeam], highlight: true };
    }
    allResults = serverScores
      .concat(preExistingResultForTeam === -1 ? currResult : [])
      .sort((a, b) => b.score - a.score);
    index = allResults.findIndex((x) => x.highlight) + 1;
  }
  useEffect(() => {
    if (index === null) {
      return;
    }
    speakText(
      `You got the ${index}${
        index === 1 ? 'st' : index === 2 ? 'nd' : index === 3 ? 'rd' : 'th'
      } place! Tomorrow you can try a quiz about ${
        G.category
      } again, maybe try a new topic today. Use your phone to choose one.`
    );
    track('Winner Scene Shown');
    return () => {
      stopSpeaking();
    };
  }, [G.category, index]);

  // return loading if no scores yet
  if (serverScores === null) {
    return <div className={styles['winner_transition-root']}>Loading...</div>;
  }

  return (
    <div className={styles['winner_transition-root']}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Scoreboard</div>
      </div>
      <Textfit className={styles.topic} forceSingleModeWidth={false} mode={'single'}>
        {G.category}
      </Textfit>
      <Textfit forceSingleModeWidth={false} mode={'single'} className={styles.date}>
        {new Date().toDateString()}
      </Textfit>
      <div className={styles.rows}>
        {allResults.slice(0, 5).map((r, i) => (
          <ScoreRow
            size={'lg'}
            highlight={r.highlight}
            index={i}
            key={i}
            correctThisRound={false}
            score={r.score}
            avatarUrls={r.playerProfiles.map((p) => p.avatarUrl)}
          />
        ))}
      </div>
      {ctx.phase === Phases.ViewingHighScore && (
        <div className={styles.footerContainer}>
          <div className={styles.title} style={{ fontSize: 50 }}>
            Pick on your mobile
          </div>
        </div>
      )}
    </div>
  );
};
