import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Phases } from '@magicyard/gptrivia-game/src/Game';
import backgroundSm from '../assets/backgroundSm.webp';
import backgroundMd from '../assets/backgroundMd.webp';
import backgroundLg from '../assets/backgroundLg.webp';
import backgroundWinner from '../assets/backgroundWinner.webp';
import { useGameObject } from '../store/GameContext';

import { WinnerTransition } from './Stages/WinnerTransition';
import { Title } from './Title';
import { Display } from '@magicyard/shared/platform/lib/api';
import { Scoring } from './Scoring';
import styles from './GameManager.module.css';
import { AudioManager } from '../AudioManager';
import { ScoringTransition } from './ScoringTransition';
import { RevealSentence } from './Stages/RevealSentence';
import { speakText, stopSpeaking } from '../textToSpeech';
import useInterval from '../hooks/useInterval';
import { Explainer } from './Explainer';
import { track } from '@magicyard/shared/src/localAnalytics';
import { DAILY_CHALLENGE_CATEGORY } from '@magicyard/gptrivia-game/src/utils/moves.util';

interface PhaseData {
  mainPage: () => JSX.Element;
  transition: (() => JSX.Element) | null;
  hasQr: boolean;
  background: 'sm' | 'md' | 'lg' | 'winner';
  transitionBackground?: 'sm' | 'md' | 'lg' | 'winner';
}

const Sync = () => {
  const { moves } = useGameObject();
  useEffect(() => {
    moves.endSync();
  }, []);
  return (
    <Title
      title={'Starting...'}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        color: 'white',
      }}
    />
  );
};

const PickACategory = () => {
  const { G } = useGameObject();
  useEffect(() => {
    speakText(
      G.didFailPrompt
        ? "The last topic you tried didn't work, try another."
        : `Use your mobile device to search for a quiz on any topic you can think of. This could be anything from '90s pop culture to quantum physics! If you can't find the topic you're interested in, go ahead and create it on the spot.`
    );

    return () => {
      stopSpeaking();
    };
  }, []);

  return (
    <div style={{ textAlign: 'center', width: 1000 }}>
      Use your mobile device to search for a quiz on any topic you can think of.
      <br />
      <br />
      If you can't find the topic you're interested in, go ahead and create it on the spot.
      <br /> {G.didFailPrompt && "The last topic you tried didn't work, try another."}
    </div>
  );
};

const CreatingQuiz = () => {
  useEffect(() => {
    track('Generating Quiz Shown');
  }, []);
  const [dots, setDots] = useState('.');
  useInterval(() => {
    setDots((old) => (old.length === 3 ? '.' : old + '.'));
  }, 1000);
  const { G } = useGameObject();
  const text =
    G.promptResults.length === 0
      ? G.category === DAILY_CHALLENGE_CATEGORY
        ? `Generating the daily challenge`
        : `Generating a trivia about ${G.category}`
      : G.category === DAILY_CHALLENGE_CATEGORY
      ? `Continuing to generate the daily challenge`
      : `More questions about ${G.category} are being generated`;
  useEffect(() => {
    speakText(text);

    return () => {
      stopSpeaking();
    };
  }, []);
  return (
    <div style={{ width: '600px', textAlign: 'center' }}>
      {text}
      {dots}
    </div>
  );
};

const StageManager = ({ display }: { display: Display | undefined }) => {
  const { ctx, G } = useGameObject();
  const { isTransition } = G;
  const phase = ctx.phase as Phases;
  const phaseToScreen: Record<Phases, PhaseData> = useMemo(
    () => ({
      [Phases.Sync]: {
        mainPage: Sync,
        transition: null,
        hasQr: false,
        background: 'sm',
      },
      [Phases.Explainer]: {
        mainPage: Explainer,
        transition: null,
        hasQr: false,
        background: 'md',
      },
      [Phases.PickCategory]: {
        mainPage: Explainer,
        transition: null,
        hasQr: false,
        background: 'md',
      },
      [Phases.ViewingHighScore]: {
        mainPage: WinnerTransition,
        transition: null,
        hasQr: false,
        background: 'winner',
      },
      [Phases.Prompting]: {
        mainPage: CreatingQuiz,
        transition: null,
        hasQr: false,
        background: 'sm',
      },
      [Phases.Playing]: {
        mainPage: RevealSentence,
        transition: null,
        hasQr: false,
        background: 'sm',
      },
      [Phases.Scoring]: {
        mainPage: Scoring,
        transition: ScoringTransition,
        hasQr: false,
        background: 'lg',
        transitionBackground: 'sm',
      },
      [Phases.GameEnd]: {
        mainPage: WinnerTransition,
        transition: null,
        hasQr: false,
        background: 'winner',
      },
    }),
    []
  );

  const isPlayersUndefined = G.players === undefined || G.players === null;
  useEffect(() => {
    let id: number | undefined;
    if (isPlayersUndefined) {
      id = window.setTimeout(() => {
        console.error('G.Players is undefined');
      }, 5000);
    }

    return () => window.clearTimeout(id);
  }, [isPlayersUndefined]);

  if (isPlayersUndefined) {
    return <Sync />;
  }

  const curr = phaseToScreen[phase ?? Phases.GameEnd];
  const currBg = G.isTransition ? curr.transitionBackground ?? curr.background : curr.background;
  return (
    <>
      <AudioManager phase={phase} isTransition={isTransition && curr.transition !== null} />
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundImage: `url(${
            currBg === 'winner'
              ? backgroundWinner
              : currBg === 'sm'
              ? backgroundSm
              : currBg === 'md'
              ? backgroundMd
              : backgroundLg
          })`,
          backgroundSize: 'contain',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {curr.hasQr && display !== undefined ? (
          <div className={styles['game_manager-qr']}>
            <div className={styles['game_manager-qr_body']}>room code: {display.code}</div>
          </div>
        ) : null}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: currBg === 'sm' ? '7.5%' : 0,
          }}
        >
          {isTransition && curr.transition !== null ? (
            <curr.transition />
          ) : (
            <curr.mainPage
              key={ctx.phase === Phases.Explainer || ctx.phase === Phases.PickCategory ? '1' : ctx.phase}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default StageManager;
