import './GameButton.css';
import React from 'react';

export const GameButton = ({
  variant,
  children,
  onClick,
  size,
  disable,
  animate,
}: {
  variant: number;
  children: React.ReactNode;
  onClick?: () => void;
  size?: 'lg' | 'sm' | 'md';
  disable?: boolean;
  animate?: boolean;
}) => {
  return (
    <div
      key={size}
      className={`game_button-root ${animate !== false ? 'game_button-root-animate' : ''} game_button-size_${
        size ?? 'sm'
      } game_button-${variant} ${disable === true ? 'game_button-root_disabled' : ''}`}
      onClick={disable === true ? undefined : onClick}
    >
      {children}
    </div>
  );
};
